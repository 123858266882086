// import React from 'react';
// import Slider from "react-slick";
// import arrow from "./assets/arrow.svg";
// import Slider1 from "./assets/Slider1.jpg";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css"; 

// const data = [
//   {
//     title: "Collage Maker and Photo Editor",
//     description: "Collage maker is a perfect app for creating collage with multiple photo grids.",
//     background: Slider1, 
//     icon: arrow
//   },
//   {
//     title: "Collage Maker and Photo Editor",
//     description: "Collage maker is a perfect app for creating collage with multiple photo grids.",
//     background: Slider1, 
//     icon: arrow
//   },
//   {
//     title: "Collage Maker and Photo Editor",
//     description: "Collage maker is a perfect app for creating collage with multiple photo grids.",
//     background: Slider1, 
//     icon: arrow
//   },
//   {
//     title: "Collage Maker and Photo Editor",
//     description: "Collage maker is a perfect app for creating collage with multiple photo grids.",
//     background: Slider1, 
//     icon: arrow
//   }
//   // Add more apps here as needed
// ];

// export default function MoreApps() {
//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 300,
//     slidesToScroll: 1,
//     autoplay: false,
//     autoplaySpeed: 3000,
//     arrows: false,
//     slidesToShow: 3,
//     responsive:[{breakpoint:600,
//       settings:{
//         slidesToShow: 1,
//         initialSlide: 1
//       }
//     },{breakpoint:1000,
//       settings:{
//         slidesToShow: 2,
//         initialSlide: 1
//       }
//     }]
//   };

//   return (
//     <section className='w-full bg-black pt-16 px-5 pb-16'>
//       {/* Header Section */}
//       <div className='flex justify-between items-center pr-5'>
//         <h4 className='text-white font-inter text-lg lg:text-2xl font-semibold leading-normal m-0'>
//           MORE APPS
//         </h4>
//         <button className='flex gap-2 justify-center items-center text-xs lg:text-sm bg-[#393232] text-white rounded-full px-3 py-2'>
//           All Apps <img src={arrow} alt='Arrow' className='w-4 h-4 lg:w-5 lg:h-5'/>
//         </button>
//       </div>
      
//       {/* Description */}
//       <div className='text-white font-inter text-xs sm:text-sm md:text-base mt-10 pr-5 pb-10'>
//         To build the best utility apps & provide digital solutions to the everyday requirements of users and enhance their app-using experience.
//       </div>

//       {/* Slider Section */}
//       <Slider {...settings}>
//         {data.map((app, index) => (
//           <div key={index} className='w-full px-2'> {/* Adjust width and padding */}
//             <img src={app.background} alt={`Slider background ${index + 1}`} className='w-full object-cover rounded-md'/>
//             <h4 className='text-white mt-4 text-sm sm:text-base md:text-lg'>{app.title}</h4>
//             <p className='text-white text-xs sm:text-sm md:text-base'>{app.description}</p>
//           </div>
//         ))}
//       </Slider>
//     </section>
//   );
// }

import React from 'react';
import Slider from "react-slick";
import arrow from "./assets/arrow.svg";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Slider1 from "./assets/Slider1.jpg";
import icon1 from "./assets/slick-assets/icon1.png";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
// import slider1 from "./assets/slick-assets/sliderimage.jpg";
import slider1 from "./assets/slick-assets/bgremoved.png";
import playstore from "./assets/slick-assets/playdownload.png";

const data = [
  {
    title: "Collage Maker and Photo Editor",
    description: "Collage maker is a perfect app for creating collage with multiple photo grids.",
    background: Slider1, 
    icon: arrow
  },
  {
    title: "Collage Maker and Photo Editor",
    description: "Collage maker is a perfect app for creating collage with multiple photo grids.",
    background: Slider1, 
    icon: arrow
  },
  {
    title: "Collage Maker and Photo Editor",
    description: "Collage maker is a perfect app for creating collage with multiple photo grids.",
    background: Slider1, 
    icon: arrow
  },
  {
    title: "Collage Maker and Photo Editor",
    description: "Collage maker is a perfect app for creating collage with multiple photo grids.",
    background: Slider1, 
    icon: arrow
  }
  // Add more apps here as needed
];

const PreviousArrow = ({ className, onClick }) => {
  return (
    <div
      className={`${className} left-[-25px] z-10`}
      onClick={onClick}
    >
      <FaArrowLeft className="text-white text-3xl" />
    </div>
  );
};

// Custom Next Arrow
const NextArrow = ({ className, onClick }) => {
  return (
    <div
      className={`${className} right-[-25px] z-10`}
      onClick={onClick}
    >
      <FaArrowRight className="text-white text-3xl" />
    </div>
  );
};

export default function MoreApps() {

  const settings = {
  dots: true,
  speed: 3000,
  slidesToShow: 1, 
  slidesToScroll: 1, 
  infinite: false,
  autoplaySpeed: 1000,
  arrows: false, 
  };

  return (
    <section className='w-full bg-black pt-16 px-2 md:px-10 pb-16 border'>
      {/* Header Section */}
      <div className='flex justify-between items-center pr-5'>
        <h4 className='text-white font-inter text-lg lg:text-2xl font-semibold leading-normal m-0'>
          MORE APPS
        </h4>
        <button className='flex gap-2 justify-center items-center text-xs lg:text-sm bg-[#393232] text-white rounded-full px-3 py-2'>
          All Apps <img src={arrow} alt='Arrow' className='w-4 h-4 lg:w-5 lg:h-5'/>
        </button>
      </div>
      
      {/* Description */}
      <div className='text-white font-inter text-xs sm:text-sm md:text-base mt-10 pr-5 pb-10'>
        To build the best utility apps & provide digital solutions to the everyday requirements of users and enhance their app-using experience.
      </div>

      {/* Slider Section */}
      <Slider {...settings} className='border'>
        {data.map((app, index) => (
          <div className='bg-white w-[99%]  flex rounded-[25px]'> 
          <div className='w-[50%] pl-10 top-[50px] relative'>
          <div className='border relative top-[0px] md:top-[40px]'>
          <img src={icon1} alt='icon description'  className='rounded-lg w-[45px] h-[45px]  md:w-[70px] md:h-[70px] mb-10'/>
            <p className='border text-black font-inter text-[16px] font-semibold leading-normal'>Calmverse : Relax and Meditate</p>
            <p className='border text-black font-inter text-[16px] font-normal leading-[121.035%] w-[60%]'>All-in-one powerful video editor app that helps to trim & cut videos, adjust sound</p>
            <div className='flex gap-3 mt-6'> 
            <img src={playstore} className='w-[127px] h-auto' alt='one'/>
            <img src={playstore}  className='w-[127px] h-auto' alt='one'/>
            </div>
          </div>
           
          </div>
          <div className='border w-[40%] md:w-[60%] flex justify-center items-center'>
          <img src={slider1} alt="slider pronto VPN" className="w-[100%] object-cover "/>
    
          </div>
          </div>
        ))}
      </Slider>
    </section>
  );
}

