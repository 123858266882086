import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import icon1 from "./assets/icons/allformats.svg";
import icon2 from "./assets/icons/icongallary.svg";
import icon3 from "./assets/icons/iconcast.svg";
import icon4 from "./assets/icons/iconhand.svg";
import icon5 from "./assets/icons/iconeye.svg";
import icon6 from "./assets/icons/icon6.svg";
import icon7 from "./assets/icons/pop-up.svg";
import pointer from './assets/pointer.svg';


const data = [{
    'icon':icon1,
    'title': "All Format",
    'description':"Have you experienced all-in-one video player functionality in one app, if not then we introduce you to the ASD Rocks Video Player app. It is a multi-media player which provides you with multiple functionalities and features. It supports online and offline content with an HD video experience you will adore."
},{
    'icon':icon2,
    'title': "Cast your phone",
    'description':"Have you experienced all-in-one video player functionality in one app, if not then we introduce you to the ASD Rocks Video Player app. It is a multi-media player which provides you with multiple functionalities and features. It supports online and offline content with an HD video experience you will adore."
},{
    'icon':icon3,
    'title': "supports Subtitles",
    'description':"Have you experienced all-in-one video player functionality in one app, if not then we introduce you to the ASD Rocks Video Player app. It is a multi-media player which provides you with multiple functionalities and features. It supports online and offline content with an HD video experience you will adore."
},{
    'icon':icon4,
    'title': "Network Streaming",
    'description':"Have you experienced all-in-one video player functionality in one app, if not then we introduce you to the ASD Rocks Video Player app. It is a multi-media player which provides you with multiple functionalities and features. It supports online and offline content with an HD video experience you will adore."
},
{
    'icon':icon5,
    'title': "",
    'description':"Have you experienced all-in-one video player functionality in one app, if not then we introduce you to the ASD Rocks Video Player app. It is a multi-media player which provides you with multiple functionalities and features. It supports online and offline content with an HD video experience you will adore."
}
,
{
    'icon':icon6,
    'title': "",
    'description':"Have you experienced all-in-one video player functionality in one app, if not then we introduce you to the ASD Rocks Video Player app. It is a multi-media player which provides you with multiple functionalities and features. It supports online and offline content with an HD video experience you will adore."
},
{
    'icon':icon7,
    'title': "",
    'description':"Have you experienced all-in-one video player functionality in one app, if not then we introduce you to the ASD Rocks Video Player app. It is a multi-media player which provides you with multiple functionalities and features. It supports online and offline content with an HD video experience you will adore."
},
{
    'icon':icon1,
    'title': "All Format",
    'description':"Have you experienced all-in-one video player functionality in one app, if not then we introduce you to the ASD Rocks Video Player app. It is a multi-media player which provides you with multiple functionalities and features. It supports online and offline content with an HD video experience you will adore."
}
]

function Keyfeatures() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);


  useEffect(() => {   
    setNav1(sliderRef1);
    setNav2(sliderRef2);
   
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    slidesToShow: 7,
    responsive:[{breakpoint:600,
      settings:{
        slidesToShow: 4,
        initialSlide: 1
      }
    },{breakpoint:1200,
      settings:{
        slidesToShow: 5,
        initialSlide: 1
      }
    }]
  };
  return (
    <div className="slider-container bg-black px-[5px] md:px-[150px] lg:px-[250px] pb-20">
    <p className="text-white py-3 font-inter text-[18px] md:text-[20px] font-bold leading-normal tracking-wider"> KEY FEATURES</p>
      <Slider
        asNavFor={nav1}
        ref={slider => (sliderRef2 = slider)}
       {...settings}
        swipeToSlide={true}
        focusOnSelect={true}
        infinite={true}
      >
          {data.map((app,index)=>{
            return(<div key={index} className="slider-icon w-[60px] h-[60px]  md:w-[90px] md:h-[90px] rounded-[15px] md:rounded-[25px] flex justify-center items-center"><img src={app.icon} className="w-[30px] h-[30px] md:w-[45px] md:h-[45px] " alt="feature icons"/></div>)
          })}
       
      </Slider>
   <div className="relative">
   <img src={pointer} alt="pointer" className="absolute w-[30px] md:w-[40px] top-[-15px] md:top-[-20px] left-4 md:left-6 z-30"/>
   <Slider className="mt-14 p-5 rounded-xl bg-[#161616] flex items-center relative"  asNavFor={nav2} arrows={false}  ref={slider => (sliderRef1 = slider) }>
      {data.map((app,index)=>{
            return(<div className="text-white  lg:w-[80%] md:p-4">
            
            <h3>{app.title}</h3> 
            <p>{app.description}</p>
        
            </div>)
          })}
      </Slider>
   </div>
     
      
    </div>
  );
}

export default Keyfeatures;

 